import { format } from "date-fns";
import React from "react";

function FilteredByList(props) {
  return (
    <div className="text-soft  mt-2">
      <div className="d-flex flex-wrap space-between ">
        {props.data.length != 0 ? (
          <div className="d-flex badge bg-outline-primary mr-2 my-0.5 flex-wrap">
            <p className="my-auto  mr-1 ">{props.title}: </p>
            {props.isStr ? (
              <p className="mr-1 my-auto  rounded mr-1  fs-11px " align="center">
                {props.isNotMap ? props.data : format(new Date(props.data), "dd MMM yyyy")}
              </p>
            ) : (
              props.data.length && props?.data?.map((item, index) => (
                <p key={`custom-filter-${index}`} className=" m-0 my-auto  rounded   fs-11px " align="center">
                  {props.isMap ? (index ? ", " : "") + item.label : (index ? ", " : "") + item}
                </p>
              ))
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FilteredByList;
