/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";
import CorrectiveTaskListCompact from "../components/CorrectiveTaskList";
import { useLocation,useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TicketManagementContext } from "../../ticket_management/TicketManagementProvider";

const CorrectiveTaskContainer = (props) => {

  const location = useLocation().search;
  const history = useHistory();
  const tab = new URLSearchParams(location).get("tab");
  const status = new URLSearchParams(location).get("status");
  const { loadTicketList, getTableSettings, correctiveList } = useContext(TicketManagementContext);
  const { pagination, filterParams, loadFilterOptions, dashboardFilterStatus } = useContext(TicketManagementContext);
const {loadAllUsers,loadAllCorrectiveTasks,tlState} = useContext(TicketManagementContext)

  const plantId = props.plantId;

  const fetchCorrectiveTasksData = async () => {
    tlState.on()
    try {
      const settings = await getTableSettings({
        entityType: "CLIENT_CORRECTIVE_PLANT",
      });
      if (settings) {
        if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
          const queryParams = new URLSearchParams(location);
          if (queryParams.has("status")) {
            queryParams.delete("status");
            history.replace({
              search: queryParams.toString(),
            });
          }
          if (plantId && !status) {
          await loadTicketList(settings,"corrective",{plantId:plantId});
          }
        }
        await loadFilterOptions()
        if (status && !dashboardFilterStatus) {
          loadTicketList(settings,"corrective",{plantId:plantId,status})
        }
      }
    } catch (error) {
      throw error
    } finally {
      tlState.off();
    }
  };

  useEffect(() => {

    fetchCorrectiveTasksData()
  }, [tab]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    loadAllUsers()
    loadAllCorrectiveTasks()
  }, [])

  return (
    <React.Fragment>
      <div className="p-4">
        <CorrectiveTaskListCompact plantId={plantId} ticketsList={correctiveList}/>
      </div>
    </React.Fragment>
  );
};

export default withPvPlantProvider(CorrectiveTaskContainer);