/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";
import PreventativeTaskListCompact from "../components/PreventativeTaskList";
import { useLocation,useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TicketManagementContext } from "../../ticket_management/TicketManagementProvider";

const PreventativeTaskContainer = (props) => {

  const location = useLocation().search;
  const history = useHistory();

  const status = new URLSearchParams(location).get("status");
  const dashboard = new URLSearchParams(location).get("dashboard");

  const { loadTicketList, getTableSettings, preventativeList } = useContext(TicketManagementContext);
  const { pagination, filterParams, loadFilterOptions, dashboardFilterStatus } = useContext(TicketManagementContext);
const {loadAllUsers,loadAllCorrectiveTasks, tlState} = useContext(TicketManagementContext)
 

  const plantId = props.plantId;

  const fetchPreventativeTasksData = async () => {
    tlState.on()
    try {
      const settings = await getTableSettings({
        entityType: "CLIENT_PREVENTATIVE_PLANT",
      });
      if (settings) {
        if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
          const queryParams = new URLSearchParams(location);
          if (queryParams.has("status")) {
            queryParams.delete("status");
            history.replace({
              search: queryParams.toString(),
            });
          }
          if (plantId && !status) {
          await loadTicketList(settings,"preventative",{plantId:plantId, dashboard});
          }
        }
        await loadFilterOptions()
        if (status && !dashboardFilterStatus) {
          loadTicketList(settings,"preventative",{plantId:plantId,status:status, dashboard})
        }
      }
    } catch (error) {
      throw error
    } finally {
      tlState.off();
    }
  };

  useEffect(() => {

    fetchPreventativeTasksData()
  }, [status]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    loadAllUsers()
  }, [])

  return (
    <React.Fragment>
      <div className="p-4">
      <PreventativeTaskListCompact plantId={plantId} ticketsList={preventativeList} />
      </div>
    </React.Fragment>
  );
};

export default withPvPlantProvider(PreventativeTaskContainer);
