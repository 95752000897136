/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";
import RemedialTaskListCompact from "../components/RemedialTaskList";
import { useLocation,useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TicketManagementContext } from "../../ticket_management/TicketManagementProvider";

const RemedialTaskContainer = (props) => {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const { loadTicketList, getTableSettings, remedialList } = useContext(TicketManagementContext);
  const { pagination, filterParams, loadFilterOptions, dashboardFilterStatus } = useContext(TicketManagementContext);
  const {loadAllUsers, tlState} = useContext(TicketManagementContext)

  const plantId = props.plantId;

  const fetchRemedialTasksData = async () => {
    tlState.on();
    try {
      const settings = await getTableSettings({
        entityType: "CLIENT_REMEDIAL_PLANT",
      });
      if (settings) {
        if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
          const queryParams = new URLSearchParams(location);
          if (queryParams.has("status")) {
            queryParams.delete("status");
            history.replace({
              search: queryParams.toString(),
            });
          }
          if (plantId && !status) {
          await loadTicketList(settings,"remedial",{plantId:plantId});
          }
        }
        await loadFilterOptions()
        if (status && !dashboardFilterStatus) {
          loadTicketList(settings,"remedial",{plantId:plantId,status:status})
        }
      }
    } catch (error) {
      throw error
    } finally {
      tlState.off();
    }
   
  };

  useEffect(() => {
    fetchRemedialTasksData()
  }, [status]);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    loadAllUsers()
  }, [])

  return (
    <React.Fragment>
      <div className="p-4">
      <RemedialTaskListCompact plantId={plantId} ticketsList={remedialList}/>
      </div>
    </React.Fragment>
  );
};

export default withPvPlantProvider(RemedialTaskContainer);
