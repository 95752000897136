/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import download from "js-file-download";
import React, { useState } from "react";
import { CardBody, CardText, Col, Form, FormGroup, Label, Row } from "reactstrap";
import { Block, Button, PreviewCard } from "../../../components/Component";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { exportClientTask } from "../TicketManagementRepository";


const ExportForm = (props) => {
  const { filterParams, plantId, taskCurrentStatus, isPreventativeMaintenance, isFromPreventative, category } = props;
  const TASK_FIELDS =
    isPreventativeMaintenance || isFromPreventative
      ? [
          {
            label: "Ticket Name",
            value: "title",
          },
          {
            label: "Identifier or Acronym",
            value: "identifier"
          },
          {
            label: "Plant",
            value: "plant.name",
          },
          {
            label: "Priority",
            value: "priority",
          },
          {
            label: "Asset Category",
            value: "assetCategory.name",
          },
          {
            label: "Category",
            value: "category",
          },
          {
            label: "Field Engineers",
            value: "fieldEngineers",
          },
          {
            label: "Attachments",
            value: "attachments"
          },
          {
            value: "code",
            label: "Code",
          },
          {
            label: "Frequency",
            value: "frequency",
          },
          {
            label: "Started At",
            value: "startedAt",
          },
          {
            label: "Resolved At",
            value: "resolvedAt",
          },
          {
            label: "Status",
            value: "status",
          },
          {
            value: "assignedTo",
            label: "Assigned Engineers",
          },
          {
            value: "months",
            label: "Month",
          },
          {
            value: "year",
            label: "Year",
          },
          {
            label: "Description",
            value: "description",
          },
          {
            label: "Comment",
            value: "comment",
          },
        ]
      :[
        {
          label: "Ticket Name",
          value: "title",
        },
        {
          label: "Identifier or Acronym",
          value: "identifier"
        },
        {
          label: "Status",
          value: "status"
        },
        {
          label: "Asset Category",
          value: "assetCategory.name",
        },
        {
          label: "Started At",
          value: "startedAt",
        },
        {
          label: "Resolved At",
          value: "resolvedAt",
        },
        {
          label: "Assigned Engineers",
          value: "assignedTo",
        },
        {
          label: "Created By",
          value: "createdById"
        },
        {
          label: "Is Archived",
          value: "isArchived"
        },
        {
          label: "Field Engineers",
          value: "fieldEngineers",
        },
        {
          label: "Attachments",
          value: "attachments"
        },
        {
          label: "Plant",
          value: "plant.name",
        },
        {
          label: "Priority",
          value: "priority",
        },
        {
          label: "Category",
          value: "category",
        },
        {
          label: "Description",
          value: "description",
        },
        {
          label: "Comment",
          value: "comment",
        },
      ];


  if (category === "corrective") {
    TASK_FIELDS.push({
      label: "Labour Hours",
      value: "labourHours",
    }, {
      label: "Parent",
      value: "parentId",
    },{
      label: "SLA",
      value: "sla.name"
    })
  }
  const [value, setValue] = useState(
    TASK_FIELDS.map((plant) => {
      return plant.value;
    })
  );
  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async () => {
    try {
      setExportLoading(true);
      if (plantId) {
        filterParams["plantId"] = plantId;
      }

      const response = await exportClientTask({
        ...filterParams,
        selectedTaskStatus:
          filterParams?.selectedTaskStatus.length === 0 ? taskCurrentStatus : filterParams?.selectedTaskStatus,
        category: props.category,
        headerData: value,
      });
      download(response, `${props.category}task.csv`);
      successToast({ description: "Successfully Exported" });
      props.setIsExportModalOpen(true);
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting tasks" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="export--correctiveForm"
        color="primary"
        size="md"
        className="mt-1"
        isLoading={exportLoading}
        onClick={handleFormSubmit}
      >
        Export Task Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0">
                {TASK_FIELDS &&
                  TASK_FIELDS.map((task, key) => {
                    return (
                      <Col lg="6" md="6" sm="12" key={`${task} + ${key}`} className="pr-0 ">
                        <FormGroup>
                          <CardBody className="m-0 p-0 pr-0 ">
                            <CardText className="m-0">
                              <input
                                key={`${task}-${key}`}
                                type="checkbox"
                                checked={value.includes(task.value)}
                                id={`${task}-${key}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setValue([task.value, ...value]);
                                  } else {
                                    const arrayIndex = value.findIndex((e) => e === task.value);
                                    if (arrayIndex > -1) {
                                      value.splice(arrayIndex, 1);
                                      setValue([...value]);
                                    }
                                  }
                                }}
                              />
                              <Label className="ml-1" for={`${task}-${key}`} check>
                                {task.label.replace(/^./, function (str) {
                                  return str.toUpperCase();
                                })}
                              </Label>
                            </CardText>
                          </CardBody>
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportForm;
