/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import ViewPvPlant from "../components/PvPlantView";
import { PvPlantContext, withPvPlantProvider } from "../PvPlantProvider";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const PvPlantViewContainer = (props) => {
  const params = useParams();
  const { plantId } = params;

  const { loadPvPlant, loadPvPlantTotalTasks } = useContext(PvPlantContext);

  useEffect(() => {
    if (plantId) {
      loadPvPlant(plantId);
      loadPvPlantTotalTasks(plantId);
    }
  }, [plantId]);

  return (
    <React.Fragment>
      <Head title="Brighter App | Pv-Plant | View" />
      <Content>
        <div>
          <ViewPvPlant />
        </div>
      </Content>
    </React.Fragment>
  );
};

export default withPvPlantProvider(PvPlantViewContainer);
