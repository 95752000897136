/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { Row, Col, FormGroup, Form, Label, CardBody, CardText } from "reactstrap";
import { Block, PreviewCard, Button } from "../../../components/Component";
import { errorToast, successToast } from "../../../components/toastify/Toastify";
import { exportClientPlant } from "../PvPlantRepository";

export const PLANT_FIELDS = [
  {
    label: "PV Plant",
    value: "name",
    field_key: "name"
  },
  {
    label: "Size",
    value: "size",
    field_key: "size"
  },
  {
    label: "Identifier or Acronym",
    value: "identifier",
    field_key: "identifier"
  },
  {
    label: "OnBoarded At",
    value: "onboardedAt",
    field_key: "onboardedAt"
  },
  {
    label: "Client",
    value: "clients.name",
    field_key: "clients"
  },
  {
    label: "Status",
    value: "status",
    field_key: "status"
  },
  {
    label: "Address",
    value: "address",
    field_key: "address"
  },
  {
    label: "Postal Code",
    value: "postalCode",
    field_key: "postalCode"
  },
  {
    label: "Plant Manager",
    value: "plantManagerName",
    field_key: "plantManager"
  },
  {
    label: "Team Leader",
    value: "teamLeaderId",
    field_key: "teamLeader"
  },
  {
    label: "Created By",
    value: "createdById",
    field_key: "createdBy"
  },
  {
    label: "Is Archived",
    value: "isArchived",
    field_key: "isArchived"
  },
  {
    label: "DNO",
    value: "dnos.name",
    field_key: "dnos"
  },
  {
    label: "Medical Center",
    value: "medicalCenters.name",
    field_key: "medicalCenters"
  },
  {
    label: "GoogleMap Link",
    value: "googleMapLink",
    field_key: "googleMapLink"
  },
  {
    label: "What3Word Link",
    value: "what3WordLink",
    field_key: "what3WordLink"
  },
  {
    label: "Field Engineers",
    value: "fieldEngineers",
    field_key: "fieldEngineerIds"
  },
  {
    label: "Attachments",
    value: "attachments",
    field_key: "attachments"
  }
];


const ExportPlant = (props) => {
  const [value, setValue] = useState(
    PLANT_FIELDS.map((plant) => {
      return plant.value;
    })
  );
  const [exportLoading, setExportLoading] = useState(false);

  const handleFormSubmit = async () => {
    try {
      setExportLoading(true);
      const response = await exportClientPlant({ headerData: value, ...props.filterParams });
      fileDownload(response, `PV Plant.csv`);
      successToast({ description: "Successfully Exported" });
      props.setIsExportModalOpen(true);
      setExportLoading(false);
    } catch (err) {
      errorToast({ description: "Error happened while exporting plants" });
      setExportLoading(false);
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": true,
  });

  const renderCreateButton = () => {
    return (
      <Button
        id="export--correctiveForm"
        color="primary"
        size="md"
        className="mt-1"
        isLoading={exportLoading}
        onClick={handleFormSubmit}
      >
        Export Plant Fields
      </Button>
    );
  };

  const renderSaveButton = () => {
    return renderCreateButton();
  };
  const selectPlantFields = (e, plant) => {
    if (e.target.checked) {
      setValue([plant.value, ...value]);
    } else {
      const arrayIndex = value.findIndex((e) => e === plant.value);
      if (arrayIndex > -1) {
        value.splice(arrayIndex, 1);
        setValue([...value]);
      }
    }
  };

  return (
    <div className="overflow-auto h-max-450px">
      <Block size="lg">
        <PreviewCard>
          <Form className={formClass} onSubmit={(e) => e.preventDefault()}>
            <Row lg="12" md="12">
              <Row className="match-height p-0">
                {PLANT_FIELDS.map((plant, key) => {
                  return (
                    <Col lg="6" md="6" sm="12" key={`${plant} - ${key}`} className="pr-0 ">
                      <FormGroup>
                        <CardBody className="m-0 p-0 pr-0 ">
                          <CardText className="m-0">
                            <input
                              key={`${plant}-${key}`}
                              type="checkbox"
                              checked={value.includes(plant.value)}
                              id={`${plant}-${key}`}
                              onChange={(e) => selectPlantFields(e, plant)}
                            />
                            <Label className="ml-1" for={`${plant}-${key}`} check>
                              {plant.label.replace(/^./, function (str) {
                                return str.toUpperCase();
                              })}
                            </Label>
                          </CardText>
                        </CardBody>
                      </FormGroup>
                    </Col>
                  );
                })}
              </Row>

              <Col xl="12" className="d-flex justify-content-end">
                {renderSaveButton()}
              </Col>
            </Row>
          </Form>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ExportPlant;
