import React, { useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import TicketListCompact from "../components/TicketList";
import { TicketManagementContext, withTicketManagementProvider } from "../TicketManagementProvider";
import { useLocation, useHistory } from "react-router-dom";

function CorrectiveTable() {
  const location = useLocation().search;
  const history = useHistory();
  const status = new URLSearchParams(location).get("status");
  const dashboard = new URLSearchParams(location).get("dashboard");
  const { loadTicketList, getTableSettings, ticketsList } = useContext(TicketManagementContext);
  const { pagination, filterParams, loadFilterOptions, dashboardFilterStatus } = useContext(TicketManagementContext);
  const { loadAllUsers, loadAllCorrectiveTasks, tlState } = useContext(TicketManagementContext)

  const searchtext = filterParams.searchText;
  const fetchCorrectiveTasksData = async () => {
    tlState.on();
    try {
      const settings = await getTableSettings({
        entityType: "CLIENT_CORRECTIVE_MAINTENANCE",
      });
  
      if (settings) {
        if (dashboardFilterStatus || (!dashboardFilterStatus && !status)) {
          const queryParams = new URLSearchParams(location);
          if (queryParams.has("status")) {
            queryParams.delete("status");
            history.replace({
              search: queryParams.toString(),
            });
          }
          await loadTicketList(settings,"corrective",{status});
          
        }
        await loadFilterOptions()
        const updatedSettings =  {
          ...settings,
          filters: { ...settings.filters, taskStatus:[status]},
        };
        if (status && !dashboardFilterStatus) {
          await loadTicketList(updatedSettings,"corrective",{status,dashboard});        
        }
      }
    } catch (error) {
      throw error
    } finally {
      tlState.off();
    }
  };

  useEffect(() => {
    fetchCorrectiveTasksData()
  }, []);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  useEffect(() => {
    loadAllUsers()
    loadAllCorrectiveTasks()
  }, [])

  return (
    <React.Fragment>
      <Head title="Pv-Plant" />
      <Content><TicketListCompact ticketsList={ticketsList} viewingPage={"corrective"}  /></Content>
    </React.Fragment>
  );
}

export default withTicketManagementProvider(CorrectiveTable);
