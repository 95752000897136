import React from "react";
import classNames from "classnames";
import { Spinner } from "reactstrap";
const Button = ({ color, size, className, outline, disabled, isLoading, type = "button", ...props }) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${color}`]: !outline,
    [`btn-outline-${color}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
  });

  const getSpinner = () => {
    if (type === "export") {
      return <Spinner size="sm" color="white" />;
    } else {
      return <Spinner size="sm" color="#526484" />;
    }
  };
  return (
    <button type={type} className={buttonClass} {...props}>
      {isLoading ? getSpinner() : props.children || "Submit"}
    </button>
  );
};
export default Button;
